import {AxiosInstance} from 'axios';
import {CALENDLY_LINK_TYPE} from '@/shared/constants/CALENDLY_LINK_TYPE';
import {IMarkInterviewLinkAsUsedRequest} from '@/shared/models/IMarkInterviewLinkAsUsedRequest';
import {IApiTalentInterviewAvailability} from '@/shared/models/IApiTalentInterviewAvailability';

export class InterviewApiService {
  constructor(private http: AxiosInstance) {}

  public async getInterviewLink(type: CALENDLY_LINK_TYPE): Promise<string> {
    return this.http.post(`/user/interview/${type}`);
  }

  public async markInterviewLinkAsUsed(req: IMarkInterviewLinkAsUsedRequest): Promise<void> {
    return this.http.post('/user/interviewScheduled', req);
  }

  public async getInterviewData(): Promise<IApiTalentInterviewAvailability[]> {
    return this.http.get('/user/interviewData');
  }
}
