import {API_SERVICE} from '@/shared/api/api.service';
import {IApiTalentInterviewAvailability} from '@/shared/models/IApiTalentInterviewAvailability';
import {InterviewMutations} from '@/modules/interview/store/mutations';

export enum InterviewActions {
  GET_INTERVIEWS = 'GET_INTERVIEWS',
}

export default {
  [InterviewActions.GET_INTERVIEWS]: async ({commit}: any) => {
    return API_SERVICE.interview.getInterviewData()
      .then((data: IApiTalentInterviewAvailability[]) => {
        commit(InterviewMutations.SET_INTERVIEWS, data);
        return Promise.resolve(data);
      })
  },
};
