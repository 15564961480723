import {AxiosInstance} from 'axios';

export class CodetableApiService {
  private cachedCodetable: CodetableResult = {};

  constructor(private http: AxiosInstance) {
  }

  public async getCodetables(...codetables: Codetables[]): Promise<CodetableResult> {
    const filteredCodetables = codetables.filter((ct) => {
      return !(this.cachedCodetable && this.cachedCodetable[ct]);
    });

    if (filteredCodetables.length === 0) return this.cachedCodetable;

    const result = await this.http.get('/public/codetable', {
      params: {
        codetables: filteredCodetables.join(',')
      }
    });

    this.cachedCodetable = {...this.cachedCodetable, ...result};
    return this.cachedCodetable;
  }
}

export type CodetableResult = {
  [Codetables.AVAILABILITIES]?: Availability[];
  [Codetables.DISABILITIES]?: Disability[];
  [Codetables.AGE_GROUPS]?: AgeGroup[];
  [Codetables.RACES]?: Race[];
  [Codetables.GENDERS]?: Gender[];
  [Codetables.MANAGEMENT_TEAM_SIZES]?: ManagementTeamSize[];
  [Codetables.TIMEZONES]?: TimeZone[];
  [Codetables.COUNTRIES]?: Country[];
  [Codetables.WORK_POSITIONS]?: WorkPosition[];
  [Codetables.WORK_DURATIONS]?: WorkDuration[];
  [Codetables.SKILLS]?: Skill[];
  [Codetables.CURRENCIES]?: Currency[];
  [Codetables.COMPANY_CATEGORIES]?: CompanyCategory[];
  [Codetables.COMPANY_SIZES]?: CompanySize[];
  [Codetables.DEVELOPER_ROLES]?: DeveloperRole[];
  [Codetables.TALENT_ENGINEERING_EXPERIENCES]?: TalentEngineeringExperience[];
  [Codetables.SKILL_EXPERIENCE_LEVELS]?: SkillExperienceLevel[];
  [Codetables.QUESTION_DIFFICULTIES]?: QuestionDifficulty[];
  [Codetables.QUESTION_TYPES]?: QuestionType[];
  [Codetables.JOB_APPLICATION_ARCHIVE_REASONS]?: JobApplicationArchiveReasons[],
};

export enum Codetables {
  TIMEZONES = 'timezones',
  COUNTRIES = 'countries',
  WORK_POSITIONS = 'work_positions',
  WORK_DURATIONS = 'work_durations',
  SKILLS = 'skills',
  CURRENCIES = 'currencies',
  COMPANY_CATEGORIES = 'company_categories',
  COMPANY_SIZES = 'company_sizes',
  DEVELOPER_ROLES = 'developer_roles',
  TALENT_ENGINEERING_EXPERIENCES = 'talent_engineering_experiences',
  SKILL_EXPERIENCE_LEVELS = 'skill_experience_levels',
  QUESTION_DIFFICULTIES = 'question_difficulties',
  QUESTION_TYPES = 'question_types',
  MANAGEMENT_TEAM_SIZES = 'management_team_sizes',
  GENDERS = 'genders',
  RACES = 'races',
  AGE_GROUPS = 'age_groups',
  DISABILITIES = 'disabilities',
  AVAILABILITIES = 'availabilities',
  JOB_APPLICATION_ARCHIVE_REASONS = 'job_application_archive_reasons',
}

export interface QuestionType {
  name: string;
  display_name: string;
}

export interface QuestionDifficulty {
  name: string;
  displayName: string;
  defaultAvailableTimeSeconds: number;
  defaultCorrectPoints: number;
  defaultIncorrectPoints: number;
}

export interface TimeZone {
  code: string;
  city: string;
}

export interface Country {
  name: string;
  code: string;
}

export const OTHER_WORK_POSITION = 'Other';

export interface WorkPosition {
  name: string;
  description: string;
}

export type WorkDuration = string;

export interface Skill {
  name: string;
}

export interface Currency {
  symbol: string;
  code: string;
  name: string;
}

export interface CompanyCategory {
  name: string;
  description: string;
}

export interface CompanySize {
  name: string;
}

export interface DeveloperRole {
  id: string;
  name: string;
  description?: string;
  displayName: string;
}

export interface TalentEngineeringExperience {
  name: string;
  displayName: string;
  visible: boolean;
  roles: string[]
}

export interface SkillExperienceLevel {
  name: string;
  displayName: string;
}

export interface TalentSkill {
  skill: string;
  experienceLevel: SkillExperienceLevel;
}

export interface ManagementTeamSize {
  name: string;
  displayName: string;
  sortOrder: number;
}

export interface Gender {
  name: string;
  displayName: string;
  sortOrder: number;
}

export interface Race {
  name: string;
  displayName: string;
  sortOrder: number;
}

export interface AgeGroup {
  name: string;
  displayName: string;
  sortOrder: number;
}

export interface Disability {
  name: string;
  displayName: string;
  sortOrder: number;
}

export interface Availability {
  name: string;
  displayName: string;
  sortOrder: number;
}

export interface JobApplicationArchiveReasons {
  name: string;
  displayName: string;
  userType: 'talent' | 'recruiter';
}
