import Vue from 'vue';
import VueRouter, {Route} from 'vue-router';
import Vuex from 'vuex';
import Vuelidate from 'vuelidate';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueMeta from 'vue-meta'

// @ts-ignore
import VueMask from 'v-mask'


import SharedModule from '@/shared';
import Modules from '@/modules';
import App from './App.vue';
import {ANALYTICS_SERVICE} from '@/shared/analytics/analytics.service';
import * as Sentry from '@sentry/vue';
import {Integrations} from '@sentry/tracing';
import {OnboardingRouter} from '@/modules/onboarding/router';


Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(Vuelidate);
Vue.use(VueAxios, axios);
Vue.use(VueMask);
Vue.use(VueMeta);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '*', beforeEnter: (from: Route, to: Route, next: any) => {
      if(from.path.startsWith('/talent')){
        next(from.fullPath.replace('/talent', ''))
      }
      else {
        next({name: OnboardingRouter.LOGIN})
      }
    }
    }
  ],
  scrollBehavior () {
    const body = document.querySelector('body');
    if(body){
      body.scrollTo(0, 0);
    }
    // return { x: 0, y: 0 }
  }
});

export const store = new Vuex.Store({});

Vue.config.productionTip = false;

Vue.prototype.$rs = {};
Vue.prototype.$track = ANALYTICS_SERVICE;

const sharedModule = new SharedModule();
sharedModule.install();
const modules = new Modules(store, router);
modules.install();

if(process.env.VUE_APP_SENTRY){
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
