import {IApiUserReferralData} from '@/shared/models/IApiUserReferralData';

export interface ReferralState {
  data: IApiUserReferralData | null,
}

const state: ReferralState = {
  data: null
};

export default state;
