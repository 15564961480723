import {QuizState} from '@/modules/quiz/store/state';

export enum QuizGetters {
  GET_QUIZZES = 'GET_QUIZZES',
  GET_QUESTION = 'GET_QUESTION',
  GET_RESULTS = 'GET_RESULTS',
}

export default {
  [QuizGetters.GET_QUIZZES]: (state: QuizState) => {
    return state.quizzes;
  },
  [QuizGetters.GET_QUESTION]: (state: QuizState) => {
    return state.question;
  },
  [QuizGetters.GET_RESULTS]: (state: QuizState) => {
    return state.results;
  },
};
