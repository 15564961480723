





















import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({
  name: 'rs-button',
})
export default class RSButton extends Vue {
  @Prop({required: false, default: 'primary'}) type?: 'primary' | 'secondary';
  @Prop({required: false, default: 'large'}) size?: 'large' | 'medium' | 'small';
  @Prop({required: false}) to?: string | any;
  @Prop({required: false}) buttonType?: string;
  @Prop({required: false, default: false}) loading?: boolean;
}
