import { RouteConfig } from 'vue-router';

export enum AssesmentsRouter {
  INDEX = 'assesments',
}

const router: RouteConfig = {
  path: 'assesments',
  component: () => import('@/modules/dashboard/modules/assesments/views/index.vue'),
  name: AssesmentsRouter.INDEX,
};

export default router;
