import {AxiosInstance} from 'axios';
import {IApiUserReferralData} from '@/shared/models/IApiUserReferralData';
import {IApiReferralCodeData} from '@/shared/models/IApiReferralCodeData';

export class ReferralApiService {
  constructor(private http: AxiosInstance) {}

  public async getReferralData(): Promise<IApiUserReferralData> {
    return this.http.get('/user/referralData');
  }

  public async getReferralCodeData(code: string): Promise<IApiReferralCodeData> {
    return this.http.get('/public/referral', {
      params: {
        code,
      }
    });
  }
}
