import {IApiJob} from '@/shared/models/IApiJob';

export interface PublicJobsState {
  jobs: IApiJob[],
  job: IApiJob | null,
  pagination: {
    page: number,
    perPage: number,
    total: number,
  },
  filters: {
    roles: string[],
  }
}

const state: PublicJobsState = {
  jobs: [],
  job: null,
  pagination: {
    page: 1,
    perPage: 30,
    total: 0,
  },
  filters: {
    roles: []
  }
};

export default state;
