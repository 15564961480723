import {AuthData} from '@/modules/onboarding/services/auth.service';
import {IApiTalent} from '@/shared/models/IApiTalent';

export interface OnboardingState {
  auth: AuthData,
  user: IApiTalent | null
}

const state: OnboardingState = {
  auth: {
    idToken: null,
    idTokenExpiration: null,
    profile: null
  },
  user: null
};

export default state;
