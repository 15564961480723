import VueRouter from 'vue-router';
import {OnboardingGuard} from '@/modules/onboarding/router/guards/onboarding.guard';
import {NoAuthGuard} from '@/modules/onboarding/router/guards/noauth.guard';
import {IndexGuard} from '@/modules/onboarding/router/guards/index.guard';
import {WelcomeStepsGuard} from '@/modules/onboarding/router/guards/welcomeSteps.guard';

export enum OnboardingRouter {
  HOME = 'home',
  LOGIN = 'login',
  SIGNUP = 'signup',
  FORGOT_PASSWORD = 'forgot-password',
  ONBOARDING = 'onboarding',
  PREFERRED_ROLES = 'preferred-roles',
  WELCOME = 'welcome',
  HOW_IT_WORKS = 'how-it-works',
  AUTH = 'auth',
  REFERRAL = 'referral',
}

const router: (router: VueRouter) => void = (router) => {
  router.addRoute({
    path: '',
    component: () => import('@/modules/onboarding/views/index.vue'),
    name: OnboardingRouter.HOME,
    beforeEnter: IndexGuard
  });

  router.addRoute({
    path: '/login',
    component: () => import('@/modules/onboarding/views/login.vue'),
    name: OnboardingRouter.LOGIN,
    beforeEnter: NoAuthGuard
  });

  router.addRoute({
    path: '/sign-up',
    component: () => import('@/modules/onboarding/views/signup.vue'),
    name: OnboardingRouter.SIGNUP,
    beforeEnter: NoAuthGuard
  });

  router.addRoute({
    path: '/forgot-password',
    component: () => import('@/modules/onboarding/views/forgot-password.vue'),
    name: OnboardingRouter.FORGOT_PASSWORD,
  });

  router.addRoute({
    path: '/onboarding',
    component: () => import('@/modules/onboarding/views/onboarding.vue'),
    name: OnboardingRouter.ONBOARDING,
    beforeEnter: OnboardingGuard
  });

  router.addRoute({
    path: '/preferred-roles',
    component: () => import('@/modules/onboarding/views/preferred-roles.vue'),
    name: OnboardingRouter.PREFERRED_ROLES,
    beforeEnter: WelcomeStepsGuard
  });

  router.addRoute({
    path: '/welcome',
    component: () => import('@/modules/onboarding/views/welcome.vue'),
    name: OnboardingRouter.WELCOME,
    beforeEnter: WelcomeStepsGuard
  });

  router.addRoute({
    path: '/how-it-works',
    component: () => import('@/modules/onboarding/views/how-it-works.vue'),
    name: OnboardingRouter.HOW_IT_WORKS,
    beforeEnter: WelcomeStepsGuard
  });

  router.addRoute({
    path: '/auth/callback',
    component: () => import('@/modules/onboarding/views/auth.vue'),
    name: OnboardingRouter.AUTH,
  });

  router.addRoute({
    path: '/r/:code',
    component: () => import('@/modules/onboarding/views/referral.vue'),
    name: OnboardingRouter.REFERRAL,
    beforeEnter: NoAuthGuard
  })
}

export default router;
