import {AxiosInstance} from 'axios';
import {IApiTalent} from '@/shared/models/IApiTalent';
import {IApiTalentRegistrationRequest} from '@/shared/models/IApiTalentRegistrationRequest';
import {IApiTalentUpdateRequest} from '@/shared/models/IApiTalentUpdateRequest';

export class AuthApiService {
  constructor(private http: AxiosInstance) {}

  getUser(): Promise<IApiTalent | null>{
    return this.http.get('/user');
  }

  registerUser(data: IApiTalentRegistrationRequest): Promise<IApiTalent>{
    return this.http.post('/user', data);
  }

  updateUser(data: IApiTalentUpdateRequest): Promise<IApiTalent> {
    return this.http.patch('/user', data);
  }

}
