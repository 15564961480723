import { API_SERVICE } from '@/shared/api/api.service';
import { PublicJobsMutations } from '@/modules/jobs/store/mutations';
import { IApiJob } from '@/shared/models/IApiJob';
import { IPagination } from '@/shared/models/IPagination';

export enum PublicJobsActions {
  GET_JOBS = 'GET_JOBS',
  SET_FILTERS = 'SET_FILTERS',
  GET_JOB = 'GET_JOB',
  LOAD_MORE = 'LOAD_MORE',
}

export default {
  [PublicJobsActions.GET_JOBS]: async ({ commit, state }: any) => {
    commit(PublicJobsMutations.SET_PAGE, 1);
    console.log(state.filters)
    return API_SERVICE.jobs.getPublicJobs(state.pagination.page, state.pagination.perPage, state.filters)
      .then((jobs: IPagination<IApiJob>) => {
        commit(PublicJobsMutations.SET_TOTAL, jobs.totalItems);
        commit(PublicJobsMutations.SET_JOBS, jobs.data);
        return Promise.resolve(jobs);
      });
  },
  [PublicJobsActions.SET_FILTERS]: async ({ commit, dispatch }: any, filters: {roles: string[]}) => {
    commit(PublicJobsMutations.SET_FILTERS, filters);
    return dispatch(PublicJobsActions.GET_JOBS)
  },
  [PublicJobsActions.GET_JOB]: async ({ commit }: any, jobId: string) => {
    return API_SERVICE.jobs.getPublicJob(jobId)
      .then((job: IApiJob) => {
        commit(PublicJobsMutations.SET_JOB, job);
        return Promise.resolve(job);
      });
  },
  [PublicJobsActions.LOAD_MORE]: async ({ commit, state }: any) => {
    commit(PublicJobsMutations.SET_PAGE, state.pagination.page + 1);
    return API_SERVICE.jobs.getPublicJobs(state.pagination.page, state.pagination.perPage, state.filters)
      .then((jobs: IPagination<IApiJob>) => {
        commit(PublicJobsMutations.SET_TOTAL, jobs.totalItems);
        commit(PublicJobsMutations.ADD_JOBS, jobs.data);
        return Promise.resolve(jobs);
      });
  },
};
