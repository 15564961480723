import {ReferralState} from '@/modules/dashboard/modules/referral/store/state';

export enum ReferralGetters {
  GET_DATA = 'GET_DATA',
}

export default {
  [ReferralGetters.GET_DATA]: (state: ReferralState) => {
    return state.data;
  },
};
