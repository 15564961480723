export enum Icons {
  CHECKMARK = 'checkmark',
  GITHUB = 'github',
  GOOGLE = 'google',
  LINKEDIN = 'linkedin',
  ARROW_RIGHT = 'arrow-right',
  CAMERA = 'camera',
  TWITTER = 'twitter',
  FACEBOOK = 'facebook',
  EMAIL = 'email',
  FULLSTACK = 'fullstack',
  FRONTEND = 'frontend',
  BACKEND = 'backend',
  MOBILE = 'mobile',
  DEVOPS = 'devops',
  DATASCIENCE = 'datascience',
  DATAENGINEER = 'dataengineer',
  QA = 'qa',
  BLOCKCHAIN = 'blockchain',
  MACHINE_LEARNING = 'machine-learning',
  CLOSE = 'close',
  X = 'x',
  EDIT = 'edit',
  MONEY = 'money',
  TIME_PIN = 'time-pin',
  ARROW_LEFT = 'arrow-left',
  TIME = 'time',
  ACCOUNT = 'account',
  SETTINGS = 'settings',
  HELP = 'help',
  LOGOUT = 'logout',
  SUITCASE = 'suitcase',
  CIRCLE_X = 'circle-x',
  CIRCLE_CHECK = 'circle-check',
  INFO = 'info',
  PROMOTE = 'promote',
  PROMOTE_ALERT = 'promote-alert',
  CHECKMARK_UNCHECKED = 'checkmark-unchecked',
  CHECKMARK_CHECKED = 'checkmark-checked',
  ALERT_CIRCLE = 'alert-circle',
  SUCCESS_CIRCLE = 'success-circle',
  FLEXIBLE_HOURS = 'flexible-hours',
  PERFORMANCE_BONUS = 'performance-bonus',
  RETIREMENT_PLAN = 'retirement-plan',
  CHILDCARE_ASSISTANCE = 'childcare-assistance',
  HEALTH_COVERAGE = 'health-coverage',
  COMPUTER = 'computer',
  COMPANY_RETREATS = 'company-retreats',
  DIGITAL_LIBRARY = 'digital-library',
  EDUCATION_STIPEND = 'education-stipend',
  EQUITY = 'equity',
  PAID_SICK_DAYS = 'paid-sick-days',
  MATERNITY_LEAVE = 'maternity-leave',
  WELLNESS_BENEFITS = 'wellness-benefits',
  PAID_TIME_OFF = 'paid-time-off',
  REMOTE_OFFICE_BUDGET = 'remote-office-budget',
  INTERNET_ALLOWANCE = 'internet-allowance',
  BIRTHDAY_VACATION = 'birthday-vacation',
  WORLD = 'world',
  SALARY = 'salary',
  CONTRACT = 'contract',
  DOLLAR_CIRCLE = 'dollar-circle',
  PIN_TIME = 'pin-time',
  UI_DESIGNER = 'ui-designer',
  MANAGER = 'manager',
  PRODUCT_MANAGER = 'product-manager',
  PROJECT_MANAGER = 'project-manager',
  DATA_ANALYST = 'data-analyst',
}


