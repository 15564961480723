import {API_SERVICE} from '@/shared/api/api.service';
import {IApiUserReferralData} from '@/shared/models/IApiUserReferralData';
import {ReferralMutations} from '@/modules/dashboard/modules/referral/store/mutations';

export enum ReferralActions {
  GET_DATA = 'GET_DATA',
}

export default {
  [ReferralActions.GET_DATA]: async ({commit}: any) => {
    return API_SERVICE.referral.getReferralData()
      .then((data: IApiUserReferralData | null) => {
        commit(ReferralMutations.SET_DATA, data);
        return Promise.resolve(data);
      })
  },
};
