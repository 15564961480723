













import {Vue, Component, Prop} from 'vue-property-decorator';
import {Icons} from '@/shared/components/icon/icons';

@Component({
  name: 'rs-icon',
})
export default class RSIcon extends Vue {
  @Prop({required: true}) name?: string;
  @Prop({required: true}) height?: string;
  @Prop({required: true}) width?: string;
  @Prop({required: false, default: false}) stretch?: boolean;

  get allowSvg() {
    return (Object.values(Icons) as string[]).includes(this.name as string);
  }

  get items(){
    return Object.values(Icons).length
  }
}
