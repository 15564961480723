import axios, {AxiosInstance} from 'axios';
import {AuthApiService} from '@/shared/api/modules/auth.api.service';
import {CoreApiService} from '@/shared/api/modules/core.api.service';
import {CodetableApiService} from '@/shared/api/modules/codetable.api.service';
import {JobsApiService} from '@/shared/api/modules/jobs.api.service';
import {ReferralApiService} from '@/shared/api/modules/referral.api.service';
import {QuizApiService} from '@/shared/api/modules/quiz.api.service';
import {InterviewApiService} from '@/shared/api/modules/interview.api.service';
import {AUTH_SERVICE, AuthData} from '@/modules/onboarding/services/auth.service';

class ApiService {
  http: AxiosInstance;
  baseUrl = '';
  token: string | null = null;

  authData: AuthData | null = null;

  core: CoreApiService;
  auth: AuthApiService;
  codetable: CodetableApiService;
  jobs: JobsApiService;
  referral: ReferralApiService;
  quiz: QuizApiService;
  interview: InterviewApiService;

  constructor() {
    this.baseUrl = process.env.VUE_APP_API_BASE_URL;

    this.authData = AUTH_SERVICE.authData();

    this.http = axios.create({
      baseURL: this.baseUrl,
      timeout: 10000,
    });

    this.http.interceptors.request.use((config) => {
      // @ts-ignore
      config.headers.Authorization = `Bearer ${this.getToken}`;
      return config;
    });

    this.http.interceptors.response.use((response) => response.data, (error) => {
      if (error.response?.status === 401) {
        this.setToken(null);
        AUTH_SERVICE.logout();
      }
      return Promise.reject({
        response: error.response,
        message: 'There was an error! Please contact remotesome developers',
      });
    });

    this.core = new CoreApiService(this.http);
    this.auth = new AuthApiService(this.http);
    this.codetable = new CodetableApiService(this.http);
    this.jobs = new JobsApiService(this.http);
    this.referral = new ReferralApiService(this.http);
    this.quiz = new QuizApiService(this.http);
    this.interview = new InterviewApiService(this.http);
  }

  get getToken() {
    return (this.authData ? this.authData.idToken : this.token) || null;
  }

  public setToken(token: string | null) {
    this.token = token;
  }
}

export const API_SERVICE = new ApiService();
