import { RouteConfig } from 'vue-router';

export enum JobsRouter {
  INDEX = 'jobs',
  SINGLE = 'jobs_single',
}

const router: RouteConfig = {
  path: 'jobs',
  component: () => import('@/modules/dashboard/modules/jobs/views/Jobs.vue'),
  children: [
    {
      path: '',
      component: () => import('@/modules/dashboard/modules/jobs/views/index.vue'),
      name: JobsRouter.INDEX,
    },
    {
      path: ':id',
      component: () => import('@/modules/dashboard/modules/jobs/views/single.vue'),
      name: JobsRouter.SINGLE,
    },
  ]
};

export default router;
