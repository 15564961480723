import {AUTH_SERVICE} from '@/modules/onboarding/services/auth.service';
import {Route} from 'vue-router';
import {OnboardingActions} from '@/modules/onboarding/store/actions';
import {store} from '@/main';
import {OnboardingRouter} from '@/modules/onboarding/router';
import {OnboardingModule} from '@/modules/onboarding/onboarding.module';

export function AuthGuard(from: Route, to: Route, next: any) {
  const authData = AUTH_SERVICE.authData();

  if (authData) {
    store.dispatch(`${OnboardingModule.namespace}/${OnboardingActions.PROCESS_LOGIN}`, AUTH_SERVICE.authData())
      .then(() => {
        next()
      })
      .catch(() => {
        next({name: OnboardingRouter.LOGIN})
      });
  } else {
    next({name: OnboardingRouter.LOGIN})
  }
}
