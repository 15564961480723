import VueRouter from 'vue-router';
import { Store } from 'vuex';
import jobsStore from './store';
import jobsRoutes from './router';

export class JobsModule {
  public static namespace = 'jobs';

  constructor(public store: Store<unknown>, public router: VueRouter) {}

  install() {
    this.store.registerModule(JobsModule.namespace, jobsStore);
    jobsRoutes(this.router)
  }
}
