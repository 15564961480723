import {PublicJobsState} from '@/modules/jobs/store/state';

export enum PublicJobsGetters {
  GET_JOBS = 'GET_JOBS',
  GET_JOB = 'GET_JOB',
  GET_TOTAL = 'GET_TOTAL',
}

export default {
  [PublicJobsGetters.GET_JOBS]: (state: PublicJobsState) => {
    return state.jobs;
  },
  [PublicJobsGetters.GET_JOB]: (state: PublicJobsState) => {
    return state.job;
  },
  [PublicJobsGetters.GET_TOTAL]: (state: PublicJobsState) => {
    return state.pagination.total;
  },
};
