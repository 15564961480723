import {API_SERVICE} from '@/shared/api/api.service';
import {IApiTalentQuiz} from '@/shared/models/IApiTalentQuiz';
import {QuizMutations} from '@/modules/quiz/store/mutations';
import {UUID} from '@/shared/constants/UUID';
import {IApiTalentQuizQuestion} from '@/shared/models/IApiTalentQuizQuestion';
import {QuizState} from '@/modules/quiz/store/state';
import {IApiNextQuestionResponse} from '@/shared/models/IApiNextQuestionResponse';

export enum QuizActions {
  GET_QUIZZES = 'GET_QUIZZES',
  START_QUIZ = 'START_QUIZ',
  NEXT_QUESTION = 'NEXT_QUESTION',
  CONTINUE_QUIZ = 'CONTINUE_QUIZ',
}

export default {
  [QuizActions.GET_QUIZZES]: async ({commit}: any) => {
    return API_SERVICE.quiz.getQuizzes()
      .then((data: IApiTalentQuiz[]) => {
        commit(QuizMutations.SET_QUIZZES, data);
        return Promise.resolve(data);
      })
  },
  [QuizActions.START_QUIZ]: async ({commit}: any, id: UUID) => {
    return API_SERVICE.quiz.startQuiz(id)
      .then((data: IApiTalentQuizQuestion) => {
        commit(QuizMutations.SET_QUESTION, data);
        commit(QuizMutations.SET_RESULTS, null);
        return Promise.resolve(data);
      })
  },
  [QuizActions.NEXT_QUESTION]
  : async ({commit, state, dispatch}
               : { commit: any, state: QuizState, dispatch: any }, answer: number | undefined) => {
    if(state.question){
      return API_SERVICE.quiz.nextQuestion(state.question?.userQuizId, {
        questionId: state.question?.questionId ,
        noAnswer: answer === undefined,
        choiceIndex: answer
      })
        .then((data: IApiNextQuestionResponse) => {
          commit(QuizMutations.SET_QUESTION, null);
          setTimeout(() => {
            commit(QuizMutations.SET_QUESTION, data.next);
          },  0)
          if(data.results){
            dispatch(QuizActions.GET_QUIZZES);
            commit(QuizMutations.SET_RESULTS, data.results);
          }
          return Promise.resolve(data);
        })
    }
    return Promise.reject('')
  },
  [QuizActions.CONTINUE_QUIZ]: async ({commit}: { commit: any }, userQuizId: string) => {
    return API_SERVICE.quiz.nextQuestion(userQuizId, undefined)
      .then((data: IApiNextQuestionResponse) => {
        commit(QuizMutations.SET_QUESTION, null);
        setTimeout(() => {
          commit(QuizMutations.SET_QUESTION, data.next);
        },  0)
        if(data.results){
          commit(QuizMutations.SET_RESULTS, data.results);
        }
        return Promise.resolve(data);
      })
  },
};
