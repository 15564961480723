import VueRouter from 'vue-router';
import {JobsGuard} from '@/modules/jobs/router/guards/jobs.guard';
import {JobGuard} from '@/modules/jobs/router/guards/job.guard';

export enum PublicJobsRouter {
  JOBS = 'public-jobs',
  JOBS_SINGLE = 'public-jobs-single',
}

const router: (router: VueRouter) => void = (router) => {
  router.addRoute({
    path: '/jobs',
    component: () => import('@/modules/jobs/views/Jobs.vue'),
    children: [
      {
        path: '',
        component: () => import('@/modules/jobs/views/index.vue'),
        beforeEnter: JobsGuard,
        name: PublicJobsRouter.JOBS
      },

      {
        path: ':id',
        component: () => import('@/modules/jobs/views/single.vue'),
        beforeEnter: JobGuard,
        name: PublicJobsRouter.JOBS_SINGLE
      },
    ]
  })
};

export default router;
