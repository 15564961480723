import VueRouter from 'vue-router';
import { Store } from 'vuex';
import DashboardModule from '@/modules/dashboard';
import OnboardingModule from '@/modules/onboarding';
import QuizModule from '@/modules/quiz';
import InterviewModule from '@/modules/interview';
import JobsModule from '@/modules/jobs';

export default class Modules {
  constructor(private store: Store<unknown>, private router: VueRouter) {}

  install(){
    const dashboardModule = new DashboardModule(this.store, this.router);
    dashboardModule.install();

    const onboardingModule = new OnboardingModule(this.store, this.router);
    onboardingModule.install();

    const quizModule = new QuizModule(this.store, this.router);
    quizModule.install();

    const interviewModule = new InterviewModule(this.store, this.router);
    interviewModule.install();

    const jobsModule = new JobsModule(this.store, this.router);
    jobsModule.install();
  }
}
