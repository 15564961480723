import {OnboardingState} from '@/modules/onboarding/store/state';

export enum OnboardingGetters {
  GET_TOKEN = 'GET_TOKEN',
  GET_USER = 'GET_USER',
}

export default {
  [OnboardingGetters.GET_TOKEN]: (state: OnboardingState) => {
    return state.auth.idToken;
  },
  [OnboardingGetters.GET_USER]: (state: OnboardingState) => {
    return state.user;
  }
};
