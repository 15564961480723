import {IApiTalentQuiz} from '@/shared/models/IApiTalentQuiz';
import {IApiTalentQuizQuestion} from '@/shared/models/IApiTalentQuizQuestion';
import {IApiTalentQuizCategoryResult} from '@/shared/models/IApiTalentQuizCategoryResult';

export interface QuizState {
  quizzes: IApiTalentQuiz[],
  question: IApiTalentQuizQuestion | null
  results: IApiTalentQuizCategoryResult[] | null
}

const state: QuizState = {
  quizzes: [],
  question: null,
  results: null
};

export default state;
