import {AUTH_SERVICE, AuthData} from '@/modules/onboarding/services/auth.service';
import {OnboardingMutations} from '@/modules/onboarding/store/mutations';
import {API_SERVICE} from '@/shared/api/api.service';
import {IApiTalent} from '@/shared/models/IApiTalent';
import {ANALYTICS_SERVICE} from '@/shared/analytics/analytics.service';
import {IApiTalentUpdateRequest} from '@/shared/models/IApiTalentUpdateRequest';

export enum OnboardingActions {
  PROCESS_LOGIN = 'PROCESS_LOGIN',
  LOAD_USER = 'LOAD_USER',
  SET_USER = 'SET_USER',
  UPDATE_USER = 'UPDATE_USER',
  LOGOUT = 'LOGOUT',
}

export default {
  [OnboardingActions.PROCESS_LOGIN]: async ({commit, dispatch}: any, payload: AuthData) => {
    commit(OnboardingMutations.SET_AUTH, payload);
    API_SERVICE.setToken(payload.idToken);
    return dispatch(OnboardingActions.LOAD_USER);
  },
  [OnboardingActions.LOAD_USER]: async ({commit}: any) => {
    return API_SERVICE.auth.getUser()
      .then((user: IApiTalent | null) => {
        commit(OnboardingMutations.SET_USER, user);
        return Promise.resolve(user);
      })
      .catch((error) => {
        if(error.response.status === 401){
          commit(OnboardingMutations.LOGOUT);
          API_SERVICE.setToken(null);
          AUTH_SERVICE.plainLogout();
        }
        return Promise.reject(error)
      })
  },
  [OnboardingActions.UPDATE_USER]: async ({commit}: any, user: IApiTalentUpdateRequest) => {
    return API_SERVICE.auth.updateUser(user)
      .then((user: IApiTalent | null) => {
        commit(OnboardingMutations.SET_USER, user);
        return Promise.resolve(user);
      })
  },
  [OnboardingActions.SET_USER]: async ({commit}: any, user: IApiTalent) => {
    commit(OnboardingMutations.SET_USER, user);
  },
  [OnboardingActions.LOGOUT]: async ({commit}: any) => {
    ANALYTICS_SERVICE.sendEvent('Logout')
    commit(OnboardingMutations.LOGOUT);
    API_SERVICE.setToken(null);
    AUTH_SERVICE.logout();
  },
};
