





























import {Component, Vue} from 'vue-property-decorator';
import RsIcon from '@/shared/components/icon/icon.vue';
import {Icons} from '@/shared/components/icon/icons';

interface ToastContent {
  title?: string,
  body: string,
  icon?: Icons,
}

@Component({
  name: 'rs-toast',
  components: {RsIcon},
})
export default class RSToast extends Vue {

  isOpen = false;

  content: ToastContent = {
    title: undefined,
    body: '',
    icon: undefined,
  }

  timeout: any = null;

  open(options: ToastContent) {
    if(this.isOpen || this.timeout){
      this.close();
      setTimeout(() => {
        this.showToast(options)
      }, 200)
    }
    else {
      this.showToast(options);
    }
  }

  private showToast(options: ToastContent){
    this.content = options;
    this.isOpen = true;

    this.timeout = setTimeout(() => {
      this.close();
    }, 3000);
  }

  private close(){
    this.isOpen = false;
    setTimeout(() => {
      this.clear();
    }, 200);
  }

  private clear(){
    this.content.title = undefined;
    this.content.body = '';
    this.content.icon = undefined;
    clearTimeout(this.timeout);
    this.timeout = null;
  }

  created() {
    // @ts-ignore
    this.$rs.toast = this.open;
  }
}
