import {AUTH_SERVICE} from '@/modules/onboarding/services/auth.service';
import {Route} from 'vue-router';
import {OnboardingActions} from '@/modules/onboarding/store/actions';
import {store} from '@/main';
import {OnboardingRouter} from '@/modules/onboarding/router';
import {OnboardingModule} from '@/modules/onboarding/onboarding.module';
import {IApiTalent} from '@/shared/models/IApiTalent';
import {ONBOARDING_STATE} from '@/shared/constants/ONBOARDING_STATE';
import {JobsRouter} from '@/modules/dashboard/modules/jobs/router';
import {PublicJobsRouter} from '@/modules/jobs/router';

export function AuthGuard(from: Route, to: Route, next: any) {
  const authData = AUTH_SERVICE.authData();
  if (authData) {
    store.dispatch(`${OnboardingModule.namespace}/${OnboardingActions.PROCESS_LOGIN}`, AUTH_SERVICE.authData())
      .then((user: IApiTalent) => {
        if (!user.uiContext?.onboardingStatus || user.uiContext?.onboardingStatus === ONBOARDING_STATE.ROLES) {
          next({name: OnboardingRouter.PREFERRED_ROLES})
          // @ts-ignore
        } else if (!user.uiContext?.onboardingStatus || user.uiContext?.onboardingStatus === ONBOARDING_STATE.ROLES) {
          from.name !== OnboardingRouter.PREFERRED_ROLES ? next({name: OnboardingRouter.PREFERRED_ROLES}) : next();
        } else if (user.uiContext?.onboardingStatus === ONBOARDING_STATE.WELCOME) {
          from.name !== OnboardingRouter.WELCOME ? next({name: OnboardingRouter.WELCOME}) : next();
        } else if (user.uiContext?.onboardingStatus === ONBOARDING_STATE.HOW_IT_WORKS) {
          from.name !== OnboardingRouter.HOW_IT_WORKS ? next({name: OnboardingRouter.HOW_IT_WORKS}) : next();
        } else {
          next()
        }
      })
      .catch(() => {
        if (from.name === JobsRouter.INDEX) {
          next({name: PublicJobsRouter.JOBS})
        } else if (from.name === JobsRouter.SINGLE) {
          next({name: PublicJobsRouter.JOBS_SINGLE, params: from.params})
        } else {
          next({name: OnboardingRouter.LOGIN})
        }
      });
  } else {
    if (from.name === JobsRouter.INDEX) {
      next({name: PublicJobsRouter.JOBS})
    } else if (from.name === JobsRouter.SINGLE) {
      next({name: PublicJobsRouter.JOBS_SINGLE, params: from.params})
    } else {
      next({name: OnboardingRouter.LOGIN})
    }
  }
}
