import VueRouter from 'vue-router';
import {AuthGuard} from '@/modules/quiz/router/guards/auth.guard';
import {OnboardingRouter} from '@/modules/onboarding/router';

export enum QuizRouter {
  QUIZ = 'QUIZ',
  QUESTION = 'QUESTION',
  RESULTS = 'RESULTS',
}

const router: (router: VueRouter) => void = (router) => {
  router.addRoute({
    path: '/quiz',
    component: () => import('@/modules/quiz/views/Quiz.vue'),
    beforeEnter: AuthGuard,
    children: [
      {
        path: '',
        redirect: {name: OnboardingRouter.SIGNUP},
      },

      {
        path: ':quizId',
        component: () => import('@/modules/quiz/views/index.vue'),
        name: QuizRouter.QUIZ
      },
      {
        path: ':quizId/question',
        component: () => import('@/modules/quiz/views/question.vue'),
        name: QuizRouter.QUESTION
      },
      {
        path: ':quizId/results',
        component: () => import('@/modules/quiz/views/results.vue'),
        name: QuizRouter.RESULTS
      },
    ]
  })
};

export default router;
