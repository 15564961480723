import VueRouter from 'vue-router';
import { Store } from 'vuex';
import onboardingStore from './store/index';
import onboardingRoutes from './router/index';

export class OnboardingModule {
  public static namespace = 'onboarding';

  constructor(public store: Store<unknown>, public router: VueRouter) {}

  install() {
    this.store.registerModule(OnboardingModule.namespace, onboardingStore);
    onboardingRoutes(this.router);
  }
}
