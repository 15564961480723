import {AxiosInstance} from 'axios';

export class CoreApiService {
  constructor(private http: AxiosInstance) {}

  uploadAvatar(file: Blob){
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post('/user/uploadAvatar', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}
