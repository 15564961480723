







import Component from 'vue-class-component';
import Vue from 'vue';
import RsGlobals from '@/shared/components/globals/globals.vue';
import {Getter} from 'vuex-class';
import {OnboardingGetters} from '@/modules/onboarding/store/getters';
import OnboardingModule from './modules/onboarding';
import {IApiTalent} from '@/shared/models/IApiTalent';
import {Watch} from 'vue-property-decorator';

@Component({
  components: {RsGlobals},
  metaInfo: {
    title: 'Home',
    titleTemplate: '%s | Remotesome'
  }
})
export default class App extends Vue {
  @Getter(OnboardingGetters.GET_USER, {namespace: OnboardingModule.namespace}) user: IApiTalent;

  @Watch('user')
  onUserChange(user: IApiTalent){
    if(user){this.$track.identify(user);}
  }
}
