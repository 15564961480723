import { Auth0DecodedHash, WebAuth } from 'auth0-js';
import authCallback from '@/modules/onboarding/constants/authCallback';
import { LOCALSTORAGE } from '@/shared/constants/LOCALSTORAGE';

export interface AuthProfile {
  exp: number;
  picture: string;
  aud: string;
  email: string;
  name: string;
  nickname: string;
  sub: string;
}

export interface AuthData {
  idToken: string | null;
  idTokenExpiration: Date | null;
  profile: AuthProfile | null;
}

class AuthService {
  private readonly webAuth: WebAuth;
  private clientID: string = process.env.VUE_APP_AUTH_TALENT_CLIENT_ID as string;
  private domain: string = process.env.VUE_APP_AUTH_TALENT_CLIENT_DOMAIN as string;
  private auth0Database: string = process.env.VUE_APP_TALENT_AUTH_DATABASE as string;

  public constructor() {
    this.webAuth = new WebAuth({
      domain: this.domain,
      clientID: this.clientID,
      redirectUri: authCallback,
      responseType: 'token id_token',
    });
  }

  public handleAuth(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.webAuth.parseHash((error, result) => {
        if (error) {
          reject(error);
        } else {
          AuthService.localLogin(result as Auth0DecodedHash);
          resolve();
        }
      });
    });
  }

  public authData(): AuthData | null {
    const idToken = localStorage.getItem(LOCALSTORAGE.ID_TOKEN);
    const idTokenExpiration = localStorage.getItem(LOCALSTORAGE.ID_TOKEN_EXPIRATION);
    const profile = localStorage.getItem(LOCALSTORAGE.AUTH_PROFILE);

    if (idToken && idTokenExpiration && profile) {
      const idTokenExpirationDate = new Date(parseInt(idTokenExpiration, 10));

      if (idTokenExpirationDate <= new Date()) {
        AuthService.localLogout();
      } else {
        return {
          idToken,
          idTokenExpiration: idTokenExpirationDate,
          profile: JSON.parse(profile),
        };
      }
    }
    return null;
  }

  public static localLogin(authResult: Auth0DecodedHash): void {
    const idToken = authResult.idToken;
    const profile = authResult.idTokenPayload;

    const tokenExpiry = new Date(profile.exp * 1000);

    localStorage.setItem(LOCALSTORAGE.ID_TOKEN, idToken as string);
    localStorage.setItem(LOCALSTORAGE.AUTH_PROFILE, JSON.stringify(profile));
    localStorage.setItem(LOCALSTORAGE.ID_TOKEN_EXPIRATION, tokenExpiry.getTime().toString());
  }

  public static localLogout() {
    localStorage.removeItem(LOCALSTORAGE.ID_TOKEN);
    localStorage.removeItem(LOCALSTORAGE.AUTH_PROFILE);
    localStorage.removeItem(LOCALSTORAGE.ID_TOKEN_EXPIRATION);
  }

  public logout(): void {
    AuthService.localLogout();
    this.webAuth.logout({
      returnTo: process.env.VUE_APP_LANDING_URL,
    });
  }

  public plainLogout() {
    AuthService.localLogout();
  }

  public authorize(type: string) {
    this.webAuth.authorize({
      connection: type,
      redirectUri: authCallback,
    });
  }

  public changePassword(email: string) {
    return new Promise((resolve, reject) => {
      this.webAuth.changePassword(
        {
          email: email ?? '',
          connection: this.auth0Database,
        },
        err => {
          if (!err) {
            resolve(null);
          } else {
            reject(err);
          }
        },
      );
    });
  }

  public signup(email: string, password: string) {
    return new Promise((resolve, reject) => {
      this.webAuth.signup(
        {
          email: email ?? '',
          password: password ?? '',
          connection: this.auth0Database,
        },
        err => {
          if (!err) {
            resolve(null);
          } else {
            reject(err);
          }
        },
      );
    });
  }

  public login(email: string, password: string) {
    return new Promise((resolve, reject) => {
      this.webAuth.login(
        {
          realm: this.auth0Database,
          username: email ?? '',
          password: password ?? '',
        },
        err => {
          if (!err) {
            resolve(null);
          } else {
            reject(err);
          }
        },
      );
    });
  }
}

export const AUTH_SERVICE = new AuthService();
