import { RouteConfig } from 'vue-router';

export enum ReferralRouter {
  INDEX = 'referral_index',
}

const router: RouteConfig = {
  path: 'referral',
  component: () => import('@/modules/dashboard/modules/referral/views/index.vue'),
  name: ReferralRouter.INDEX,
};

export default router;
