import {IApiTalentInterviewAvailability} from '@/shared/models/IApiTalentInterviewAvailability';
import {InterviewState} from '@/modules/interview/store/state';

export enum InterviewMutations {
  SET_INTERVIEWS = 'SET_INTERVIEWS',
}

export default {
  [InterviewMutations.SET_INTERVIEWS]: (state: InterviewState, interviews: IApiTalentInterviewAvailability[]) => {
    state.interviews = interviews;
  },
};
