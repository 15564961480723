import state from './state';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';
import ReferralModule from '@/modules/dashboard/modules/referral';
import AssesmentsModule from '@/modules/dashboard/modules/assesments';
import JobsModule from '@/modules/dashboard/modules/jobs';

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {
    [AssesmentsModule.namespace]: AssesmentsModule.store,
    [JobsModule.namespace]: JobsModule.store,
    [ReferralModule.namespace]: ReferralModule.store,
  }
};
