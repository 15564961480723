import VueRouter from 'vue-router';
import { Store } from 'vuex';
import dashboardStore from './store';
import dashboardRoutes from './router';

export class DashboardModule {
  public static namespace = 'dashboard';

  constructor(public store: Store<unknown>, public router: VueRouter) {}

  install() {
    this.store.registerModule(DashboardModule.namespace, dashboardStore);
    dashboardRoutes(this.router)
  }
}
