import {IApiTalentInterviewAvailability} from '@/shared/models/IApiTalentInterviewAvailability';

export interface InterviewState {
  interviews: IApiTalentInterviewAvailability[]
}

const state: InterviewState = {
  interviews: []
};

export default state;
