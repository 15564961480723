import VueRouter from 'vue-router';
import { Store } from 'vuex';
import quizStore from './store';
import quizRoutes from './router';

export class QuizModule {
  public static namespace = 'quiz';

  constructor(public store: Store<unknown>, public router: VueRouter) {}

  install() {
    this.store.registerModule(QuizModule.namespace, quizStore);
    quizRoutes(this.router)
  }
}
