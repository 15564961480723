import {IApiTalentQuiz} from '@/shared/models/IApiTalentQuiz';
import {QuizState} from '@/modules/quiz/store/state';
import {IApiTalentQuizQuestion} from '@/shared/models/IApiTalentQuizQuestion';
import {IApiTalentQuizCategoryResult} from '@/shared/models/IApiTalentQuizCategoryResult';

export enum QuizMutations {
  SET_QUIZZES = 'SET_QUIZZES',
  SET_QUESTION = 'SET_QUESTION',
  SET_RESULTS = 'SET_RESULTS',
}

export default {
  [QuizMutations.SET_QUIZZES]: (state: QuizState, quizzes: IApiTalentQuiz[]) => {
    state.quizzes = quizzes;
  },
  [QuizMutations.SET_QUESTION]: (state: QuizState, question: IApiTalentQuizQuestion) => {
    state.question = question;
  },
  [QuizMutations.SET_RESULTS]: (state: QuizState, results: IApiTalentQuizCategoryResult[]) => {
    state.results = results;
  },
};
