import posthog from 'posthog-js';
import {IApiTalent} from '@/shared/models/IApiTalent';

class AnalyticsService {
  window: any = window;

  constructor() {
    if (this.isPosthog) {
      posthog.init(process.env.VUE_APP_POSTHOG_API_KEY, {api_host: process.env.VUE_APP_POSTHOG_BASE_URL});
    }
  }

  get isPosthog() {
    return process.env.VUE_APP_POSTHOG_API_KEY && process.env.VUE_APP_POSTHOG_BASE_URL;
  }

  get isGTM() {
    return process.env.VUE_APP_GTM_KEY
  }

  sendEvent(event: string, properties?: posthog.Properties) {
    if (this.isPosthog) {
      posthog.capture(event, {
        ...properties,
        'platform': 'talent',
      })
    }
    if (this.isGTM) {
      this.window.dataLayer = this.window.dataLayer || [];
      this.window.dataLayer.push({
        'event': `gtm-${event}`,
        'platform': 'talent',
        ...properties
      });
    }
  }

  identify(user: IApiTalent) {
    if (this.isPosthog) {
      posthog.identify(user.userId, {
        interestStatus: user.properties.TALENT_INTEREST_STATUS,
        aviability: user.properties.AVAILABILITY,
        skills: user.properties.TALENT_MAIN_SKILLS?.map((el) => `${el.TALENT_SKILL_NAME} - ${el.TALENT_SKILL_EXPERIENCE}`),
        role: user.properties.TALENT_ROLE_EXPERIENCES?.map((role) => `${role.TALENT_ROLE} - ${role.TALENT_ROLE_YEARS}`),
        email: user.mainEmail,
        country: user.country,
        location: user.properties.LOCATION,
        platform: 'talent'
      })
    }
  }
}

export const ANALYTICS_SERVICE = new AnalyticsService();
