import {AUTH_SERVICE} from '@/modules/onboarding/services/auth.service';
import {Route} from 'vue-router';
import {OnboardingActions} from '@/modules/onboarding/store/actions';
import {store} from '@/main';
import {OnboardingModule} from '@/modules/onboarding/onboarding.module';
import {JobsRouter} from '@/modules/dashboard/modules/jobs/router';

export function JobGuard(from: Route, to: Route, next: any) {
  const authData = AUTH_SERVICE.authData();

  if (authData) {
    const id = from.params.id;
    store.dispatch(`${OnboardingModule.namespace}/${OnboardingActions.PROCESS_LOGIN}`, AUTH_SERVICE.authData())
      .then(() => {
        next({name: JobsRouter.SINGLE, params: {id}})
      })
      .catch(() => {
        next()
      });
  } else {
    next()
  }
}
