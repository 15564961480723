import {OnboardingState} from '@/modules/onboarding/store/state';
import {AUTH_SERVICE, AuthData} from '@/modules/onboarding/services/auth.service';
import {IApiTalent} from '@/shared/models/IApiTalent';

export enum OnboardingMutations {
  SET_AUTH = 'SET_AUTH',
  SET_USER = 'SET_USER',
  LOGOUT = 'LOGOUT',
}

export default {
  [OnboardingMutations.SET_AUTH]: (state: OnboardingState, auth: AuthData) => {
    state.auth = auth;
  },
  [OnboardingMutations.SET_USER]: (state: OnboardingState, user: IApiTalent) => {
    state.user = user;
  },
  [OnboardingMutations.LOGOUT]: (state: OnboardingState) => {
    state.auth.idToken = null;
    state.auth.profile = null;
    state.auth.idTokenExpiration = null;
    state.user = null;
    AUTH_SERVICE.plainLogout();
  }
};
