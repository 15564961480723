import {InterviewState} from '@/modules/interview/store/state';

export enum InterviewGetters {
  GET_INTERVIEWS = 'GET_INTERVIEWS',
}

export default {
  [InterviewGetters.GET_INTERVIEWS]: (state: InterviewState) => {
    return state.interviews;
  },
};
