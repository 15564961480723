import VueRouter from 'vue-router';
import { Store } from 'vuex';
import interviewStore from './store';
import interviewRoutes from './router';

export class InterviewModule {
  public static namespace = 'interview';

  constructor(public store: Store<unknown>, public router: VueRouter) {}

  install() {
    this.store.registerModule(InterviewModule.namespace, interviewStore);
    interviewRoutes(this.router);
  }
}
