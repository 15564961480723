import {JobsState} from '@/modules/dashboard/modules/jobs/store/state';
import {IApiJob} from '@/shared/models/IApiJob';

export enum JobsMutations {
  SET_JOBS = 'SET_JOBS',
  SET_FEATURED_JOBS = 'SET_FEATURED_JOBS',
  SET_APPLIED_JOBS = 'SET_APPLIED_JOBS',
  ADD_JOBS = 'ADD_JOBS',
  SET_JOB = 'SET_JOB',
  SET_PAGE = 'SET_PAGE',
  SET_TOTAL = 'SET_TOTAL',
  SET_FILTERS = 'SET_FILTERS',
}

export default {
  [JobsMutations.SET_APPLIED_JOBS]: (state: JobsState, jobs: IApiJob[]) => {
    state.appliedJobs = jobs;
  },
  [JobsMutations.SET_JOBS]: (state: JobsState, jobs: IApiJob[]) => {
    state.jobs = jobs;
  },
  [JobsMutations.SET_FEATURED_JOBS]: (state: JobsState, jobs: IApiJob[]) => {
    state.featuredJobs = jobs;
  },
  [JobsMutations.ADD_JOBS]: (state: JobsState, jobs: IApiJob[]) => {
    state.jobs = [...state.jobs, ...jobs];
  },
  [JobsMutations.SET_JOB]: (state: JobsState, job: IApiJob) => {
    state.job = job;
  },
  [JobsMutations.SET_PAGE]: (state: JobsState, page: number) => {
    state.pagination.page = page;
  },
  [JobsMutations.SET_TOTAL]: (state: JobsState, total: number) => {
    state.pagination.total = total;
  },
  [JobsMutations.SET_FILTERS]: (state: JobsState, filters: {roles: string[], timezone: string | null}) => {
    state.filters = filters;
  },
};
