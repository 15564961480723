import {JobsState} from '@/modules/dashboard/modules/jobs/store/state';

export enum JobsGetters {
  GET_JOBS = 'GET_JOBS',
  GET_FEATURED_JOBS = 'GET_FEATURED_JOBS',
  GET_JOB = 'GET_JOB',
  GET_TOTAL = 'GET_TOTAL',
  GET_APPLIED_JOBS = 'GET_APPLIED_JOBS',
}

export default {
  [JobsGetters.GET_JOBS]: (state: JobsState) => {
    return state.jobs;
  },
  [JobsGetters.GET_FEATURED_JOBS]: (state: JobsState) => {
    return state.featuredJobs;
  },
  [JobsGetters.GET_JOB]: (state: JobsState) => {
    return state.job;
  },
  [JobsGetters.GET_TOTAL]: (state: JobsState) => {
    return state.pagination.total;
  },
  [JobsGetters.GET_APPLIED_JOBS]: (state: JobsState) => {
    return state.appliedJobs;
  },
};
