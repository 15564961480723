import {ReferralState} from '@/modules/dashboard/modules/referral/store/state';
import {IApiUserReferralData} from '@/shared/models/IApiUserReferralData';

export enum ReferralMutations {
  SET_DATA = 'SET_DATA',
}

export default {
  [ReferralMutations.SET_DATA]: (state: ReferralState, data: IApiUserReferralData) => {
    state.data = data;
  },
};
