import { RouteConfig } from 'vue-router';

export enum ApplicationsRouter {
  INDEX = 'applications',
}

const router: RouteConfig = {
  path: 'applications',
  component: () => import('@/modules/dashboard/modules/applications/views/Applications.vue'),
  children: [
    {
      path: '',
      component: () => import('@/modules/dashboard/modules/applications/views/index.vue'),
      name: ApplicationsRouter.INDEX,
    },
  ]
};

export default router;
