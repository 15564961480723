import {IApiJob} from '@/shared/models/IApiJob';
import {PublicJobsState} from '@/modules/jobs/store/state';

export enum PublicJobsMutations {
  SET_JOBS = 'SET_JOBS',
  ADD_JOBS = 'ADD_JOBS',
  SET_JOB = 'SET_JOB',
  SET_PAGE = 'SET_PAGE',
  SET_TOTAL = 'SET_TOTAL',
  SET_FILTERS = 'SET_FILTERS',
}

export default {
  [PublicJobsMutations.SET_JOBS]: (state: PublicJobsState, jobs: IApiJob[]) => {
    state.jobs = jobs;
  },
  [PublicJobsMutations.ADD_JOBS]: (state: PublicJobsState, jobs: IApiJob[]) => {
    state.jobs = [...state.jobs, ...jobs];
  },
  [PublicJobsMutations.SET_JOB]: (state: PublicJobsState, job: IApiJob) => {
    state.job = job;
  },
  [PublicJobsMutations.SET_PAGE]: (state: PublicJobsState, page: number) => {
    state.pagination.page = page;
  },
  [PublicJobsMutations.SET_TOTAL]: (state: PublicJobsState, total: number) => {
    state.pagination.total = total;
  },
  [PublicJobsMutations.SET_FILTERS]: (state: PublicJobsState, filters: {roles: string[]}) => {
    state.filters = filters;
  },
};
