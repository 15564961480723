import {AxiosInstance} from 'axios';
import {UUID} from '@/shared/constants/UUID';
import {IApiTalentQuiz} from '@/shared/models/IApiTalentQuiz';
import {IApiTalentQuizQuestion} from '@/shared/models/IApiTalentQuizQuestion';
import {IApiNextQuestionRequest} from '@/shared/models/IApiNextQuestionRequest';
import {IApiNextQuestionResponse} from '@/shared/models/IApiNextQuestionResponse';

export class QuizApiService {
  constructor(private http: AxiosInstance) {}

  public async getQuizzes(): Promise<IApiTalentQuiz[]> {
    return this.http.get('/user/quizzes');
  }

  public async startQuiz(quizId: UUID): Promise<IApiTalentQuizQuestion> {
    return this.http.post(`/user/quizzes/${quizId}/start`);
  }

  public async nextQuestion(userQuizId: UUID, req?: IApiNextQuestionRequest): Promise<IApiNextQuestionResponse> {
    return this.http.post(`/user/quizzes/${userQuizId}/next-question`, req);
  }
}
