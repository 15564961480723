import {AUTH_SERVICE} from '@/modules/onboarding/services/auth.service';
import {Route} from 'vue-router';
import {OnboardingActions} from '@/modules/onboarding/store/actions';
import {store} from '@/main';
import {DashboardRouter} from '@/modules/dashboard/router';
import {OnboardingRouter} from '@/modules/onboarding/router';
import {OnboardingModule} from '@/modules/onboarding/onboarding.module';
import {ONBOARDING_STATE} from '@/shared/constants/ONBOARDING_STATE';

export function WelcomeStepsGuard(from: Route, to: Route, next: any) {
  const authData = AUTH_SERVICE.authData();

  if (authData) {
    store.dispatch(`${OnboardingModule.namespace}/${OnboardingActions.PROCESS_LOGIN}`, AUTH_SERVICE.authData())
      .then((user) => {
        if (!user.uiContext?.onboardingStatus || user.uiContext?.onboardingStatus === ONBOARDING_STATE.ROLES) {
          from.name !== OnboardingRouter.PREFERRED_ROLES ? next({name: OnboardingRouter.PREFERRED_ROLES}) : next();
        } else if(user.uiContext?.onboardingStatus === ONBOARDING_STATE.WELCOME) {
          from.name !== OnboardingRouter.WELCOME ? next({name: OnboardingRouter.WELCOME}) : next();
        } else if(user.uiContext?.onboardingStatus === ONBOARDING_STATE.HOW_IT_WORKS) {
          from.name !== OnboardingRouter.HOW_IT_WORKS ? next({name: OnboardingRouter.HOW_IT_WORKS}) : next();
        } else {
          from.name !== DashboardRouter.INDEX ? next({name: DashboardRouter.INDEX}) : next();
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          next({name: OnboardingRouter.LOGIN})
        } else {
          next({name: OnboardingRouter.ONBOARDING})
        }
      });
  } else {
    next({name: OnboardingRouter.SIGNUP})
  }
}
