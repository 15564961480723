







import {Component, Vue} from 'vue-property-decorator';
import RsToast from './toast.vue';
import RsAlert from '@/shared/components/globals/alert.vue';

@Component({
  name: 'rs-globals',
  components: {RsAlert, RsToast},
})
export default class RSGlobals extends Vue {}
