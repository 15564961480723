import VueRouter from 'vue-router';
import {AuthGuard} from '@/modules/dashboard/router/guards/auth.guard';
import ReferralModule from '@/modules/dashboard/modules/referral';
import ProfileModule from '@/modules/dashboard/modules/profile';
import AssesmentsModule from '@/modules/dashboard/modules/assesments';
import JobsModule from '@/modules/dashboard/modules/jobs';
import ApplicationsModule from '@/modules/dashboard/modules/applications';

export enum DashboardRouter {
  INDEX = 'dashboard',
}

const router: (router: VueRouter) => void = (router) => {
  router.addRoute({
    path: '/dashboard',
    component: () => import('@/modules/dashboard/views/Dashboard.vue'),
    beforeEnter: AuthGuard,
    children: [
      {
        path: '',
        component: () => import('@/modules/dashboard/views/index.vue'),
        name: DashboardRouter.INDEX,
      },
      ProfileModule.router,
      AssesmentsModule.router,
      JobsModule.router,
      ApplicationsModule.router,
      ReferralModule.router
    ]
  })
}

export default router;
