import { AxiosInstance } from 'axios';
import {IApiJob, IApiJobApplication, IApiJobApplicationOverview} from '@/shared/models/IApiJob';
import { IPagination } from '@/shared/models/IPagination';

export class JobsApiService {
  constructor(private http: AxiosInstance) {}

  public async getPublicJobs(page = 1, perPage = 20, filters?: {roles: string[]})
    : Promise<IPagination<IApiJob>> {
    console.log(page, perPage, filters)
    return this.http.get('/public/jobs/list', {
      params: {
        perPage,
        page,
        roles: !!filters?.roles.length ? filters.roles.join(',') : undefined,
      },
    });
  }

  public async getPublicJob(jobId: string): Promise<IApiJob> {
    return this.http.get(`/public/jobs/${jobId}`);
  }

  public async getJobs(page = 1, perPage = 20, filters?: {roles: string[], timezone: string}): Promise<IPagination<IApiJob>> {
    return this.http.get('/user/jobs', {
      params: {
        perPage,
        page,
        roles: !!filters?.roles.length ? filters.roles.join(',') : undefined,
        timezone: !!filters?.timezone ? filters?.timezone : undefined
      },
    });
  }

  public async getAppliedJobs(page = 1, perPage = 5): Promise<IPagination<IApiJob>> {
    return this.http.get('/user/jobs/applied', {
      params: {
        perPage,
        page,
      },
    });
  }

  public async getJob(jobId: string): Promise<IApiJob> {
    return this.http.get(`/user/jobs/${jobId}`);
  }

  public async applyForJob(jobId: string): Promise<void> {
    return this.http.post(`/user/jobs/${jobId}/apply`);
  }

  public async unapplyForJob(jobId: string, archiveReason: string): Promise<void> {
    return this.http.post(`/user/jobs/${jobId}/withdraw`, {
      archiveReason
    });
  }

  public async getApplicationOverview(): Promise<IApiJobApplicationOverview[]> {
    return this.http.get('/user/jobs/applicationOverview');
  }

  public async getApplications(): Promise<IApiJobApplication[]> {
    return this.http.get('/user/jobs/applications');
  }
}
