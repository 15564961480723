import { RouteConfig } from 'vue-router';

export enum ProfileRouter {
  INDEX = 'profile',
  EDIT_INDEX = 'profile_edit_index',
  EDIT = 'profile_edit',
  SETTINGS = 'profile_settings',
}

export enum ProfileEditSections {
  PERSONAL_INFO = 'personal-info',
  SKILLS = 'skills',
  WORK_EXPERIENCE = 'work-experience',
  REFERENCES = 'references',
  PREFERENCES = 'preferences'
}

const router: RouteConfig = {
  path: 'profile',
  component: () => import('@/modules/dashboard/modules/profile/views/Profile.vue'),
  children: [
    {
      path: '',
      redirect: {name: ProfileRouter.EDIT, params: {section: ProfileEditSections.PERSONAL_INFO}},
      name: ProfileRouter.INDEX,
    },
    {
      path: 'edit',
      redirect: {name: ProfileRouter.EDIT, params: {section: ProfileEditSections.PERSONAL_INFO}},
      name: ProfileRouter.EDIT_INDEX
    },
    {
      path: 'edit/:section',
      component: () => import('@/modules/dashboard/modules/profile/views/edit.vue'),
      name: ProfileRouter.EDIT
    },
    {
      path: 'settings',
      component: () => import('@/modules/dashboard/modules/profile/views/settings.vue'),
      name: ProfileRouter.SETTINGS
    },
  ]
};

export default router;
