










































import {Component, Vue} from 'vue-property-decorator';
import RsIcon from '@/shared/components/icon/icon.vue';
import {Icons} from '@/shared/components/icon/icons';
import RsModal from '@/shared/components/modal/modal.vue';
import RsButton from '@/shared/components/buttons/button.vue';

interface AlertContent {
  type?: 'danger' | 'success',
  title: string,
  body?: string,
  icon?: Icons,
  iconType?: 'warning' | 'normal',
  confirm?: {
    text: string;
    action: () => void
  },
  cancel?: {
    text: string;
    action: () => void
  }
}

@Component({
  name: 'rs-alert',
  components: {RsButton, RsModal, RsIcon},
})
export default class RSAlert extends Vue {

  Icons = Icons;

  isOpen = false;

  content: AlertContent = {
    type: 'danger',
    title: '',
    body: '',
    icon: undefined,
    iconType: 'normal',
    confirm: undefined,
    cancel: undefined
  }

  timeout: any = null;

  open(options: AlertContent) {
    if(this.isOpen || this.timeout){
      this.close();
      setTimeout(() => {
        this.showAlert(options)
      }, 200)
    }
    else {
      this.showAlert(options);
    }
  }

  private showAlert(options: AlertContent){
    this.content = options;
    this.isOpen = true;
  }

  async close(confirmed?: boolean){
    this.isOpen = false;
    setTimeout(() => {
      this.clear();
    }, 200);

    if (confirmed) {
      if (this.content.confirm?.action) {
        await this.content.confirm.action();
      }
    } else if (this.content.cancel?.action){
      await this.content.cancel.action();
    }
  }

  private clear(){
    this.content.title = '';
    this.content.body = '';
    this.content.icon = undefined;
    this.content.confirm = undefined;
    this.content.cancel = undefined;
    clearTimeout(this.timeout);
    this.timeout = null;
  }

  created() {
    // @ts-ignore
    this.$rs.alert = {
      open: this.open,
      close: this.close
    };
  }
}
