import VueRouter from 'vue-router';
import { AuthGuard } from '@/modules/quiz/router/guards/auth.guard';

export enum InterviewRouter {
  INDEX = 'INTERVIEW',
  SCHEDULE = 'SCHEDULE',
}

const router: (router: VueRouter) => void = (router) => {
  router.addRoute({
    path: '/interview',
    component: () => import('@/modules/interview/views/Interview.vue'),
    beforeEnter: AuthGuard,
    children: [
      {
        path: '',
        component: () => import('@/modules/interview/views/index.vue'),
        name: InterviewRouter.INDEX,
      },
      {
        path: ':type',
        component: () => import('@/modules/interview/views/schedule.vue'),
        name: InterviewRouter.SCHEDULE,
      },
    ],
  });
};

export default router;
