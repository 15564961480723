import {IApiJob} from '@/shared/models/IApiJob';

export interface JobsState {
  jobs: IApiJob[],
  featuredJobs: IApiJob[],
  appliedJobs: IApiJob[],
  job: IApiJob | null,
  pagination: {
    page: number,
    perPage: number,
    total: number,
  },
  filters: {
    roles: string[],
    timezone: string | null;
  }
}

const state: JobsState = {
  jobs: [],
  featuredJobs: [],
  appliedJobs: [],
  job: null,
  pagination: {
    page: 1,
    perPage: 30,
    total: 0,
  },
  filters: {
    roles: [],
    timezone: null
  }
};

export default state;
