import { API_SERVICE } from '@/shared/api/api.service';
import { JobsMutations } from '@/modules/dashboard/modules/jobs/store/mutations';
import { IApiJob } from '@/shared/models/IApiJob';
import { IPagination } from '@/shared/models/IPagination';

export enum JobsActions {
  GET_JOBS = 'GET_JOBS',
  GET_FEATURED_JOBS = 'GET_FEATURED_JOBS',
  GET_APPLIED_JOBS = 'GET_APPLIED_JOBS',
  GET_JOB = 'GET_JOB',
  APPLY_FOR_JOB = 'APPLY_FOR_JOB',
  LOAD_MORE = 'LOAD_MORE',
  SET_FILTERS = 'SET_FILTERS'
}

export default {
  [JobsActions.GET_APPLIED_JOBS]: async ({ commit }: any) => {
    return API_SERVICE.jobs.getAppliedJobs()
      .then((res) => {
        commit(JobsMutations.SET_APPLIED_JOBS, res.data);
        Promise.resolve(res);
      })
  },
  [JobsActions.GET_JOBS]: async ({ commit, state }: any) => {
    commit(JobsMutations.SET_PAGE, 1);
    return API_SERVICE.jobs.getJobs(state.pagination.page, state.pagination.perPage, state.filters)
      .then((jobs: IPagination<IApiJob>) => {
        commit(JobsMutations.SET_TOTAL, jobs.totalItems);
        commit(JobsMutations.SET_JOBS, jobs.data);
        return Promise.resolve(jobs);
      });
  },
  [JobsActions.GET_FEATURED_JOBS]: async ({ commit }: any) => {
    return API_SERVICE.jobs.getJobs(1, 3)
      .then((jobs: IPagination<IApiJob>) => {
        commit(JobsMutations.SET_FEATURED_JOBS, jobs.data);
        return Promise.resolve(jobs);
      });
  },
  [JobsActions.SET_FILTERS]: async ({ commit,  dispatch }: any, filters: {roles: string[], timezone: string}) => {
    commit(JobsMutations.SET_FILTERS, filters);
    return dispatch(JobsActions.GET_JOBS)
  },
  [JobsActions.GET_JOB]: async ({ commit }: any, jobId: string) => {
    return API_SERVICE.jobs.getJob(jobId)
      .then((job: IApiJob) => {
        commit(JobsMutations.SET_JOB, job);
        return Promise.resolve(job);
      });
  },
  [JobsActions.APPLY_FOR_JOB]: async ({ }: any, jobId: string) => {
    return API_SERVICE.jobs.applyForJob(jobId);
  },
  [JobsActions.LOAD_MORE]: async ({ commit, state }: any) => {
    commit(JobsMutations.SET_PAGE, state.pagination.page + 1);
    return API_SERVICE.jobs.getJobs(state.pagination.page, state.pagination.perPage, state.filters)
      .then((jobs: IPagination<IApiJob>) => {
        commit(JobsMutations.SET_TOTAL, jobs.totalItems);
        commit(JobsMutations.ADD_JOBS, jobs.data);
        return Promise.resolve(jobs);
      });
  },
};
