



































import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import RsIcon from '@/shared/components/icon/icon.vue';
import {Icons} from '@/shared/components/icon/icons';

@Component({
  name: 'rs-modal',
  components: {RsIcon},
})
export default class RSLoading extends Vue {
  @Prop() open: boolean;
  @Prop({required: false, default: ''}) title: string;
  @Prop({required: false, default: 'default'}) type?: 'default' | 'medium';
  @Prop({required: false, default: false}) removeX?: boolean;

  Icons = Icons

  @Watch('open')
  onOpenChange(show: boolean){
    if (!show) {
      window.removeEventListener('keyup', this.onEscapeKeyUp);
    } else {
      window.addEventListener('keyup', this.onEscapeKeyUp);
    }
  }

  onEscapeKeyUp(event: any) {
    if (event.which === 27) {
      this.close();
    }
  }

  close() {
    this.$emit('close');
  }
}
